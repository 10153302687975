import API from "../../routes/api.routes";
import { ApiMananger } from "../apiMananger";

const coachApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getCoachs: build.query({
      // query: () => API.COACHS_API.GETALL(),
      query: () => API.COACHS_API.GETALLv2(),
      providesTags: ["Coachs"],
    }),
    getCoachBasicInfo: build.query({
      // query: () => API.COACHS_API.GETALL(),
      query: () => API.COACHS_API.GETCOACHSBASICINFO(),
      providesTags: [""],
    }),
    getCoachsGlobalMetrics: build.query({
      // query: () => API.COACHS_API.GETALL(),
      query: () => API.COACHS_API.GETGLOBALMETRICS(),
      providesTags: [""],
    }),
    getCoachsMetrics: build.query({
      // query: () => API.COACHS_API.GETALL(),
      query: () => API.COACHS_API.GETMETRICS(),
      providesTags: [""],
    }),
    getFristCoachs: build.query({
      query: ({ limit }) =>
        limit ? `coachs/?limit=${limit}&offset=0` : "coachs/",
      providesTags: ["Coachs"],
    }),
    getCoachSessions: build.query({
      query: ({ coachId, filter }) =>
        filter
          ? `m1/coachs/${coachId}/connexion/?${filter}`
          : `m1/coachs/${coachId}/connexion/`,
      providesTags: ["Coachs"],
    }),
    getCoachAppels: build.query({
      query: ({coachId}) =>  `m1/coachs/${coachId}/appels/`,
      providesTags: ["Coachs"],
    }),
    getFristCoachs: build.query({
      query: ({ limit }) =>
        limit ? `coachs/?limit=${limit}&offset=0` : "coachs/",
      providesTags: ["Coachs"],
    }),
    addCoachs: build.mutation({
      query({ body, token }) {
        return {
          url: API.COACHS_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Coachs"],
    }),
    EditCoachs: build.mutation({
      query({ body, token }) {
        return {
          url: API.COACHS_API.EDIT(body?.id),
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Coachs"],
    }),
    deleteCoachs: build.mutation({
      query(id) {
        return {
          url: API.COACHS_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Coachs"],
    }),
  }),
});

export const {
  useGetCoachsQuery,
  useGetFristCoachsQuery,
  useAddCoachsMutation,
  useEditCoachsMutation,
  useDeleteCoachsMutation,
  useGetCoachSessionsQuery,
  useGetCoachAppelsQuery,
  useGetCoachsGlobalMetricsQuery,
  useGetCoachsMetricsQuery,
  useGetCoachBasicInfoQuery
} = coachApi;
