import React, { useState } from "react";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import { useGetVillesQuery } from "../../../redux/features/villeApi";
import {
  useAddEcolesMutation,
  useDeleteEcolesMutation,
  useEditEcolesMutation,
  useGetEcolesQuery,
} from "../../../redux/features/ecoleApi";
import {
  Button,
  Divider,
  Loader,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import EcoleTable from "../components/EcoleTable";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useGetDirecteursQuery } from "../../../redux/features/directeurApi";
import DeleteAction from "../actions/DeleteAction";
import EditAction from "../actions/EditAction";
import AddAction from "../actions/AddAction";

const EcoleListePage = () => {
  const { data, isLoading } = useGetEcolesQuery();
  const { data: villes } = useGetVillesQuery();
  const { data: directeurs } = useGetDirecteursQuery();

  console.log(directeurs)

  const [addEcoles, { isLoading: addLoading }] = useAddEcolesMutation();
  const [editEcoles, { isLoading: editLoading }] = useEditEcolesMutation();
  const [deleteEcoles] = useDeleteEcolesMutation();

  const [query, setQuery] = useState("");
  const [itemEdited, setItemEdited] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      name: "",
      directeurId: "",
      villeId: "",
    },

    validate: {
      name: (value) => (value === "" ? "Ce champ est obligatoire" : null),
      directeurId: (value) =>
        value === "" ? "Ce champ est obligatoire" : null,
      villeId: (value) => (value === "" ? "Ce champ est obligatoire" : null),
    },
  });

  // console.log({ data });

  return (
    <Box>
      {/* <Text size={20}>EcolePage</Text> */}
      <Flex position={"apart"}>
        <TextInput
          placeholder={"Rechercher une ecole"}
          onChange={(value) => setQuery(value.target.value.toLowerCase())}
          value={query}
        />
        <Button onClick={open}>Ajouter</Button>
      </Flex>
      <Divider my={20} />

      {isLoading ? (
        <Loader />
      ) : (
        <EcoleTable
          data={data?.data.filterData(["name"], query) ?? []}
          handleEdit={(id, item) => {
            setItemEdited(id);
            form.setValues({ ...item });
            open();
          }}
          handleDelete={async (id) => await DeleteAction(deleteEcoles, id)}
        />
      )}

      {/* My modal */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          form.reset();
          setItemEdited(null);
        }}
        title={itemEdited ? "Modifier ecole" : "Ajouter ecole"}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            if (itemEdited) {
              await EditAction(editEcoles, values);
            } else {
              await AddAction(addEcoles, values);
            }
            close();
            form.reset();
          })}
        >
          <TextInput
            label={"Nom de l'ecole"}
            placeholder={"niveau name"}
            {...form.getInputProps("name")}
          />

          <Select
            mt={15}
            label={"Selectionner une ville"}
            data={
              villes?.data.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("villeId")}
          />

          <Select
            mt={15}
            label={"Selectionner un directeur"}
            data={
              directeurs?.data.filter((item) => item?.ecole === null || !item?.ecole).map((item) => ({
                value: item?.id,
                label: item?.fullName,
              })) ?? []
            }
            placeholder={"Selectionner"}
            {...form.getInputProps("directeurId")}
          />

          <Divider my={15} />
          <Flex position={"end"} gap={15}>
            <Button color={"gray"} onClick={close}>
              Annuler
            </Button>
            <Button type={"submit"} loading={addLoading || editLoading}>
              {itemEdited ? "Modifier" : "Ajouter"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </Box>
  );
};

export default EcoleListePage;
